<template lang="pug">
  div
    .row.justify-content-center
      .col-11.header
        div(style="margin: 0px auto;width: 50%;")
          img.client_logo(:src="Logo" v-openlog)
    .main_box
      h3.text-center Oficina Virtual {{ version }}
      h3.text-center En construcción
</template>

<script>
import { mapState } from "vuex";
import LogoColmedica from "@/assets/colmedica_logo.png";

export default {
  name: "Home",

  data: () => ({
    LogoColmedica
  }),

  computed: {
    ...mapState(["version", "env"]),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  }
};
</script>
<style lang="scss" scoped>
.main_box {
  width: 75vw;
  margin: auto;
  font-size: 1.4em;
}
.header {
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.client_logo {
  width: 120px;
  height: auto;
}
</style>
